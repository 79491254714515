import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import TopDots from "../../static/images/about-blue-dots.svg"
import YellowDot from "../../static/images/large-yellow-dot.svg"
import PinkLine from "../../static/images/pink-line.svg"
import PinkDot from "../../static/images/right-pink-dot.svg"
import BlueDots from "../../static/images/about-blue-dots-2.svg"

import Img from 'gatsby-image';



const About = props => (
  <Layout>
    <Helmet>
      <title>About us | IBDmate</title>
    </Helmet>
    <div className="layout-about">
      <div className="yellow-bg">
        <div className="wrapper">
          <div className="content">
            <h1>About us</h1>
            <p>IBDmate was created by <a href="https://www.ibdrelief.com" target="_blank" rel="noreferrer">IBDrelief</a>, a UK-based social enterprise dedicated to improving the lives of people around the world affected by IBD. IBDrelief worked closely with the paediatric IBD team at Addenbrooke’s Hospital in Cambridge UK and many young IBD patients to develop IBDmate. IBD teams at other paediatric hospitals in the UK have also contributed to the videos, and new content continues to be added all the time.</p>
          </div>
          <div className="vectors">
            <img src={TopDots} id="blue-dots" alt="" />
            <img src={YellowDot} id="yellow-dot" alt="" />
            <img src={PinkLine} id="pink-line" alt="" />
          </div>
        </div>
      </div>
      <div className="white-bg" id="about" style={{backgroundImage: `url(${BlueDots}),url(${PinkDot})`}}>
        <div className="wrapper">
          <div className="left-column">
            <h2>IBDrelief</h2>
            <p>We are a for-purpose digital healthcare agency for IBD. We work in partnership with NHS trusts, hospitals and other healthcare services, charities, and organisations who share our ethos of putting patients first to improve their lives with IBD.</p>
            <p>IBDrelief was started by Seb Tucknott 2015. After being diagnosed with ulcerative colitis in 2008 Seb spent many years unaware of exactly what UC was and the impacts it could have on his life. When first researching IBD he found the information was complicated and fragmented. To try to make life easier for others affected by IBD to find information he decided to created a support platform for those living with IBD.</p>
            <p><a href="https://www.ibdrelief.com" target="_blank" rel="noreferrer">Visit IBDrelief</a></p>
          </div>
          <div className="right-column">
            <div className="person">
              <Img
                fluid={props.data.Seb.childImageSharp.fluid}
                alt="Seb Tucknott"
              />
              <p><strong>Seb Tucknott</strong>
              <br />CEO & Co-founder</p>
            </div>
            <div className="person">
              <Img
                fluid={props.data.Emily.childImageSharp.fluid}
                alt="Emily Bridges"
              />
              <p><strong>Emily Bridges</strong>
              <br />COO & Co-founder</p>
            </div>
            <div className="person">
              <Img
                fluid={props.data.Faye.childImageSharp.fluid}
                alt="Faye Spiller"
              />
              <p><strong>Faye Spiller</strong>
              <br />Marketing assistant</p>
            </div>
            <div className="person">
              <Img
                fluid={props.data.Tom.childImageSharp.fluid}
                alt="Tom Vance"
              />
              <p><strong>Tom Vance</strong>
              <br />Lead software engineer</p>
            </div>
            <div className="person">
              <Img
                fluid={props.data.Jess.childImageSharp.fluid}
                alt="Lucas Tucknott"
              />
              <p><strong>Lucas Tucknott</strong>
              <br />Videographer</p>
            </div>
          </div>
        </div>
      </div>
      { /*
        <div className="white-bg" id="medical">
        <div className="wrapper">
          <div className="content">
            <h2>Our medical experts</h2>
            <p>Nam dapibus nisl vitae elit fringilla rutrum. Aenean sollicitudin, erat a elementum rutrum, neque sem pretium metus, quis mollis nisl nunc et massa.</p>
          </div>
          <div className="people">
            <div className="person">
              <img src={Emily} alt="Emily Tucknott" />
              <p><strong>Emily Tucknott</strong>
              <br />COO & Co-founder</p>
            </div>
            <div className="person">
              <img src={Emily} alt="Emily Tucknott" />
              <p><strong>Emily Tucknott</strong>
              <br />COO & Co-founder</p>
            </div>
            <div className="person">
              <img src={Emily} alt="Emily Tucknott" />
              <p><strong>Emily Tucknott</strong>
              <br />COO & Co-founder</p>
            </div>
            <div className="person">
              <img src={Emily} alt="Emily Tucknott" />
              <p><strong>Emily Tucknott</strong>
              <br />COO & Co-founder</p>
            </div>
          </div>
          <div className="logos">

          </div>
        </div>
      </div>
      */ }
    </div>
  </Layout>
)

export default About


export const pageQuery = graphql`
  query {
    Seb: file(relativePath: { eq: "team/seb.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Emily: file(relativePath: { eq: "team/emily.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Faye: file(relativePath: { eq: "team/faye.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Tom: file(relativePath: { eq: "team/tom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Jess: file(relativePath: { eq: "team/jess.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
